import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  Switch,
  FormControlLabel
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import EnhancedTableHead from "../EnchancedTableHead/EnhancedTableHead";
import SearchBar from "../SearchBar";
import TablePaginationAction from "../TablePaginationActions";
import { useStyles } from "./styles";
import moment from "moment";

const getComparator = (order, orderBy,sortCaseInsensitive) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy,sortCaseInsensitive)
    : (a, b) => -descendingComparator(a, b, orderBy,sortCaseInsensitive);
};

function convertStringToMinutes(strtime) {

    let time = 0;
    if (strtime === "") return 0;
    const strtimeArray = strtime.split(" ");
    if (strtimeArray.length === 1 && strtimeArray[0].includes('m')) {
      let mins = strtimeArray[0].replace(/m/g, '');
      time = parseInt(mins, 10);
    } else if (strtimeArray.length === 1 && strtimeArray[0].includes('h')) {
      let hrs = strtimeArray[0].replace(/h/g, '');
      time = parseInt(hrs, 10) * 60;
    } else {
      let hrs = strtimeArray[0].replace(/h/g, '');
      let timehrs = parseInt(hrs, 10) * 60;

      let mins = strtimeArray[1].replace(/m/g, '');
      let timemins = parseInt(mins, 10);

      time = timehrs + timemins;
    }

    return time;
}

function compareDateStrings(aDateTime, bDateTime) {
  // if value is not a date (eg: "no date"), then order before any real dates
  if(!moment(aDateTime,['MM/DD/YYYY hh:mm:ss a','YYYY-MM-DDTHH:mm']).isValid()) {
    return -1;
  }
  if(!moment(bDateTime,['MM/DD/YYYY hh:mm:ss a','YYYY-MM-DDTHH:mm']).isValid()) {
    return 1;
  }
  if(moment(aDateTime) < moment(bDateTime)) return -1;
  if(moment(aDateTime) > moment(bDateTime)) return 1;
  return 0;
}

function compareTimeStrings (aValue, bValue) {
  let atime, btime

  atime = convertStringToMinutes(aValue);
  btime = convertStringToMinutes(bValue);

  if (btime < atime) return -1;
  if (btime > atime) return 1;
  return 0;
}

function compareFloats(aValue, bValue) {
  if (aValue === "" && bValue !== "") return 1
  else if (aValue !== "" && bValue === "") return -1
  else if (aValue === "" && bValue === "") return 0
  else {
    let feea = parseFloat(aValue.replace(/\$/g, ''));
    let feeb = parseFloat(bValue.replace(/\$/g, ''));

    if (feeb < feea) return -1;
    if (feeb > feea) return 1;
    return 0;
  }
}

function compareStrings (aValue, bValue) {
  if (typeof aValue === 'string' || aValue instanceof String) {
    aValue = aValue.toLowerCase();
  }
  if (typeof bValue === 'string' || bValue instanceof String) {
    bValue = bValue.toLowerCase();
  }
if (bValue < aValue) return -1;
if (bValue > aValue) return 1;
return 0;
}

function compareIntegers(aValue, bValue) {
  const a = parseInt(aValue, 10);
  const b = parseInt(bValue, 10);
  
  if (isNaN(a) && isNaN(b)) return 0;
  if (isNaN(a)) return 1;
  if (isNaN(b)) return -1;
  
  return a - b;
}

const descendingComparator = (a, b, orderBy, sortCaseInsensitive) => {
  const aValue = a[orderBy];
  const bValue = b[orderBy];
  const r = new RegExp(/(a|\d+)\s(year[s]?|month[s]?|day[s]?|hour[s]?|minute[s]?|second[s]?)\s(ago)/);

  if (orderBy === "id") {
    return compareIntegers(bValue, aValue);
  } else if (orderBy === "duration") {
    // "duration" column used by Pay On Entry 
    return compareTimeStrings(aValue.trim(), bValue.trim());    
  } else if (orderBy === "fee") {
    // "fee" column used by Pay On Entry 
    return compareFloats(aValue.trim(), bValue.trim());
  } else if (moment(aValue,['MM/DD/YYYY hh:mm:ss a','YYYY-MM-DDTHH:mm'], true).isValid() 
      || moment(bValue,['MM/DD/YYYY hh:mm:ss a','YYYY-MM-DDTHH:mm'], true).isValid()) {
    // it is a date
    return compareDateStrings(aValue, bValue);
  } else if (r.test(aValue) || r.test(bValue)) {
      // if you can sort these you are smarter than I
      return compareStrings(aValue, b.Value);      
  } else if (sortCaseInsensitive) {
    return compareStrings(aValue.trim(), bValue.trim());
  } 
};

const stableSort = (array, comparator) => {
  if (array?.length > 0) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
  }
};

const ListView = ({
  handleAddButton,
  editButtonToggle,
  editButtonEnabledFunc,
  handleEditButton,
  deleteButtonToggle,
  deleteButtonEnabledFunc,
  handleDeleteButton,
  titleBarToggle,
  titleBarTextToggle,
  titleBarTitleText,
  titleBarAddButtonToggle,
  addButtonText,
  showTableHead,
  data,
  paginationToggle,
  editButtonText,
  rowsToShowPerPage,
  rowsToShowDefault,
  handlePageChange,
  currentPage,
  totalCount,
  queryPagination,
  showSearch,
  handleSearchChange,
  searchValue,
  additionalButtons,
  sortCaseInsensitive,
  isSwitchToggleEnabled,
  handleSwitchToggle,
  sortByID,
  searchDelay,
  autoFocusSearchBar,
  ...props
}) => {
  /*
  minimum required values per object in array
  {
    name: "",
    id: ""
  }
  */

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsToShowDefault);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(sortByID ? "id" : "name");
  // Scheduled report data don't have "name" 
  let headerArray = [];

  if (data?.length <= 0) data = [];

  //Header will not order by on nulls without this
  if (data?.length > 0) {
    data.forEach(row => {
      Object.keys(row).forEach(key => {
        if (row[key] == undefined || row[key] == null || row[key] == "") {
          row[key] = "null";
        }
      });
    });
  }

  if (data != null && data != undefined && data?.length != 0) {
    Object.keys(data[0]).map(rowValue => {
      let name = rowValue.toString();
      // Scheduled report uses "enabledSwitch" as switch and "fromemail" as subtext and "reportName" for permission
      if (!name.includes("id") && name !== "enabledSwitch" && name !== "fromEmail" && name !== "reportName") {
        headerArray.push({
          id: name,
          numeric: false,
          disablePadding: false,
          label: name.charAt(0).toUpperCase() + rowValue.slice(1)
        });
      }
    });
    headerArray.push({
      id: "",
      numeric: false,
      disablePadding: false,
      label: ""
    });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  const handleNonQueryPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Paper style={{ width: "100%" }} elevation={props.elevation ?? 0}>
        {titleBarToggle && (
          <Grid
            container
            className={clsx(`${titleBarTitleText}-panel`, classes.cardHeader)}
          >
            <Grid item xs={8} md={8} lg={8} className={classes.headerTitle}>
              {titleBarTextToggle && (
                <Typography className={clsx("title")}>
                  {titleBarTitleText}
                </Typography>
              )}
            </Grid>

            {titleBarAddButtonToggle && (
              <Grid
                item
                xs={4}
                md={4}
                lg={4}
                className={classes.headerAddButton}
              >
                <Button
                  className={clsx("add-button")}
                  variant="contained"
                  color="primary"
                  onClick={handleAddButton}
                >
                  Add {addButtonText}
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        <Grid
          container
          className={clsx("table-container", classes.tableContainer)}
        >
          {showSearch && (
            <Grid
              item
              xs={showSearch ? 8 : 0}
              md={showSearch ? 8 : 0}
              lg={showSearch ? 8 : 0}
              className={clsx("pagination", classes.headerTitle)}
            >
              <SearchBar
                className={clsx(
                  "validations-account-search-bar",
                  classes.searchBar
                )}
                delay={searchDelay ?? 100}
                onChange={handleSearchChange}
                defaultSearchTerm={searchValue}
                autoFocus={autoFocusSearchBar}
              />
            </Grid>
          )}

          {paginationToggle && (
            <Grid
              item
              xs={showSearch ? 4 : 12}
              md={showSearch ? 4 : 12}
              lg={showSearch ? 4 : 12}
              className={clsx("pagination", classes.headerTitle)}
            >
              {!queryPagination ? (
                <TablePagination
                  component="div"
                  rowsPerPageOptions={rowsToShowPerPage}
                  className={clsx("table-pagination", classes.centerContentRow)}
                  count={data?.length}
                  rowsPerPage={rowsPerPage ?? rowsToShowDefault}
                  page={page ?? 0}
                  onChangePage={handleNonQueryPageChange}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationAction}
                />
              ) : (
                <Pagination
                  className={clsx("table-pagination", classes.centerContentRow)}
                  page={currentPage}
                  onChange={handlePageChange}
                  count={totalCount}
                  color="primary"
                  shape="rounded"
                />
              )}
            </Grid>
          )}
          <Table className={clsx("table", classes.table)}>
            {showTableHead &&
              headerArray != undefined &&
              headerArray != null && (
                <EnhancedTableHead
                  classes={clsx("header-row", classes.tableHeader)}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={data?.length}
                  headerRow={headerArray}
                ></EnhancedTableHead>
              )}

            {data == undefined || data == null || data?.length <= 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography
                      className={clsx(
                        "no-data-message",
                        classes.centerContentColumn,
                        classes.noDataMessage
                      )}
                    >
                      There is no data to display.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody data-testid = "table-body">
                {(rowsPerPage > 0
                  ? stableSort(data, getComparator(order, orderBy,sortCaseInsensitive))?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : stableSort(data, getComparator(order, orderBy,sortCaseInsensitive))
                ).map(row => {
                  return (
                    <>
                      <TableRow
                        key={row?.id ?? 0}
                        className={clsx(
                          `${row.name}-table-row`,
                          classes.tableRow
                        )}
                      >
                        {Object.entries(row).map((rowValue, index) => {
                          // Scheduled report uses "enabledSwitch" as switch and "fromemail" as subtext and "reportName" for permission
                          if (!rowValue[0].includes("id") && rowValue[0] !== "enabledSwitch" && rowValue[0] !== "fromEmail" && rowValue[0] !== "reportName") {
                            return (
                              <>
                                <TableCell
                                  data-value={rowValue[1].toString()}
                                  className={clsx(
                                    `${Object.keys(row)[index].replace(
                                      " ",
                                      ""
                                    )}-table-cell`,
                                    classes.tableCell
                                  )}
                                >
                                  <Typography
                                    className={clsx(
                                      `${Object.keys(row)[index].replace(
                                        " ",
                                        ""
                                      )}-text`
                                    )}
                                  >
                                    {rowValue[1].toString()}
                                  </Typography>
                                  {sortByID && rowValue[0] === "report" && row.fromEmail && (
                                    <Typography variant="caption" style={{ display: 'block', marginTop: '4px' }}>
                                      {row.fromEmail}
                                    </Typography>
                                  )}
                                </TableCell>
                              </>
                            );
                          }
                        })}
                        <TableCell
                          className={clsx(
                            `buttons-table-cell`,
                            classes.buttonsTableCell
                          )}
                          align="right"
                        >
                          {editButtonToggle && (
                            <Button
                              disabled={!editButtonEnabledFunc(row)}
                              variant="outlined"
                              color="primary"
                              onClick={() => handleEditButton(row.id)}
                              className={clsx(`edit-button`)}
                            >
                              {editButtonText}
                            </Button>
                          )}

                          {isSwitchToggleEnabled && (
                            <FormControlLabel
                              value="enabledSwitch"
                              control={
                                <Switch
                                  checked={row.enabledSwitch === true}
                                  color="primary"
                                  className={classes.switch}
                                  onChange={(event) => handleSwitchToggle(row.id, event.target.checked)}
                                />}
                                label={
                                  <Typography variant="caption" className={classes.switchLabel}>
                                    Enable/Disable
                                  </Typography>
                                }
                              labelPlacement="top"
                            />
                          )}

                          {deleteButtonToggle && (
                            <Button
                              disabled={!deleteButtonEnabledFunc(row)}
                              variant="outlined"
                              color="secondary"
                              className={clsx(
                                `delete-button`,
                                classes.tableButtons
                              )}
                              onClick={() => handleDeleteButton(row.id)}
                            >
                              Delete
                            </Button>
                          )}

                          {additionalButtons &&
                            additionalButtons.map(button => (
                              <Button
                                key={`${button.title}-${row.id}-button`}
                                variant="outlined"
                                color="primary"
                                className={clsx(
                                  `${button.title}-button`,
                                  classes.tableButtons
                                )}
                                onClick={() => button.handleClick(row.id)}
                              >
                                {button.title}
                              </Button>
                            ))}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </Grid>
      </Paper>
    </>
  );
};

ListView.defaultProps = {
  handleAddButton: () => {},
  editButtonToggle: true,
  editButtonEnabledFunc: () => {
    return true;
  },
  handleEditButton: () => {},
  deleteButtonToggle: true,
  deleteButtonEnabledFunc: () => {
    return true;
  },
  handleDeleteButton: () => {},
  titleBarToggle: true,
  titleBarTextToggle: true,
  titleBarTitleText: "List view",
  titleBarAddButtonToggle: true,
  addButtonText: "",
  showTableHead: true,
  data: [],
  paginationToggle: true,
  hideBottomPagination: true,
  editButtonText: "Edit",
  rowsToShowPerPage: [15, 25, 50],
  rowsToShowDefault: 15,
  handlePageChange: () => {},
  currentPage: 0,
  totalCount: 0,
  queryPagination: false,
  showSearch: false,
  handleSearchChange: () => {},
  searchValue: "",
  isSwitchToggleEnabled: false,
  sortByID: false,
  searchDelay: 100,
  autoFocusSearchBar: false
};

ListView.propTypes = {
  handleAddButton: PropTypes.func,
  editButtonToggle: PropTypes.bool,
  editButtonEnabledFunc: PropTypes.func,
  handleEditButton: PropTypes.func,
  deleteButtonToggle: PropTypes.bool,
  handleDeleteButton: PropTypes.func,
  deleteButtonEnabledFunc: PropTypes.func,
  titleBarToggle: PropTypes.bool,
  titleBarTextToggle: PropTypes.bool,
  titleBarTitleText: PropTypes.string,
  titleBarAddButtonToggle: PropTypes.bool,
  addButtonText: PropTypes.string,
  showTableHead: PropTypes.bool,
  data: PropTypes.array,
  paginationToggle: PropTypes.bool,
  hideBottomPagination: PropTypes.bool,
  editButtonText: PropTypes.string,
  rowsToShowPerPage: PropTypes.array,
  rowsToShowDefault: PropTypes.number,
  handlePageChange: PropTypes.func,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  queryPagination: PropTypes.bool,
  showSearch: PropTypes.bool,
  handleSearchChange: PropTypes.func,
  searchValue: PropTypes.string,
  isSwitchToggleEnabled: PropTypes.bool,
  handleSwitchToggle: PropTypes.func,
  sortByID: PropTypes.bool,
  searchDelay: PropTypes.number,
  autoFocusSearchBar: PropTypes.bool
};

export default ListView;
