import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import PageableEntity, { PaginatorLocations } from '../../PageableEntity';
import {
  Grid,
  Checkbox,
  useMediaQuery,
  useTheme,
  TableCell
} from '@material-ui/core';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { onFiltersChanged, clearContractFilters } from '../../../reducers/contracts/contractReducer';
import apiClient from '../../../auth/apiClient';
import { useEnqueueSnackbar } from '../../../hooks/useEnqueueSnackbar';
import useHasPermissions from '../../../hooks/useHasPermissions';
import clsx from 'clsx';
import useCurrentFacility from '../../../hooks/useCurrentFacility';
import useContractContext from '../../../hooks/useContractContext';
import { useStyles } from './style';
import useCurrentFacilityTimezone from '../../../hooks/useCurrentFacilityTimezone';
import { isUndefined, isNull } from 'lodash';
import GroupContractService from '../../../services/GroupContractService';
import { useSelector } from "react-redux";

const groupContractService = new GroupContractService(apiClient);

function getRowsByHeight(height) {
  return Math.round(height / 130);
}

const AvailableAccessHoldersList = ({
  onAddClick,
  contractID,
  hideFilterBtn
}) => {
  const { facilityID } = useCurrentFacility();
  const { convertUtcDateToFormat } = useCurrentFacilityTimezone();
  const { height } = useWindowDimensions();
  const [itemLimit, setItemLimit] = useState(getRowsByHeight(height));
  const [accessHolders, setAccessHolders] = useState({
    totalCount: 0,
    collection: [],
    display: []
  });
  const enqueueSnackbar = useEnqueueSnackbar();
  const { hasPermissions } = useHasPermissions();
  const accessHoldersView = hasPermissions(['accessholders.view']);
  const [values, dispatch] = useContractContext();
  const [selectedAccessHolders, setSelectedAccessHolders] = useState({});
  const classes = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const editAccessHoldersAndGroupContract = hasPermissions([
    'groupcontracts.edit',
    'accessholders.edit'
  ]);
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);

  const formatDate = useCallback((dateStr) => {
    if (isUndefined(dateStr) || isNull(dateStr)) {
      return '';
    }
    return convertUtcDateToFormat(dateStr);
  }, [convertUtcDateToFormat]);

  const handleAccessHolderSelect = (id) => {
    setSelectedAccessHolders((prev) => ({
      ...prev,
      [id]: prev[id] != true ? true : false
    }));
  };

  const [selectedCategory, setSelectedCategory] = useState('name');

  const fillAvailableAccessHoldersList = async (page = values.availableContractAccessHoldersPage || 1, limit = itemLimit) => {
    if (!accessHoldersView) return;
    
    try {
      const response = await groupContractService.getGroupContractAccessHoldersByCategory(
        facilityGroupID || facilityID,
        contractID,
        false,
        limit,
        (page - 1) * limit,
        values.searchTerm,
        selectedCategory
      );
      
      setAccessHolders({
        totalCount: response?.data?.totalCount,
        collection: response?.data?.collection
      });
    } catch (ex) {
      enqueueSnackbar('Failed to retrieve access holders', {
        variant: 'error',
        tag: 'fetchAccessHoldersError'
      });
    }
  };


  useEffect(() => {
    if (height) {
      const newLimit = getRowsByHeight(height);
      setItemLimit(newLimit);
      if (accessHolders.collection.length > 0) {
        fillAvailableAccessHoldersList(values.availableContractAccessHoldersPage, newLimit);
      }
    }
  }, [height]);

  useEffect(() => {
    var formatted = accessHolders?.collection?.map(
      (accessHolder, index) => {
        return {
          id: accessHolder.accessHolderID,
          displayContent: (
            <Grid
              container
              className={clsx('available-access-holders-row')}
              direction="row"
              data-testid={`grid-item-${accessHolder.accessHolderID}`}
              data-access-holder-name={accessHolder.firstName}
            >
              <Grid
                item
                className={clsx('grid-item-checkbox')}
                xs={3}
                md={1}
                data-testid="grid-item-checkbox"
              >
                <Checkbox
                  inputProps={{
                    'data-id': `grid-item-checkbox-${accessHolder.accessHolderID}`,
                    'data-testid': `grid-item-checkbox-${accessHolder.accessHolderID}`
                  }}
                  checked={
                    selectedAccessHolders[accessHolder.accessHolderID] ===
                    true
                  }
                  onChange={() => {
                    handleAccessHolderSelect(accessHolder.accessHolderID);
                  }}
                  disabled={!editAccessHoldersAndGroupContract}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TableCell
                  className={clsx('ah-name', classes.tableCell)}
                  data-id={`ah-name-${index}`}
                  data-testid={`ah-name-${index}`}
                  data-value={`${accessHolder.firstName} ${accessHolder.lastName}`}
                >
                  {(small ? 'Name: ' : '') +
                    `${accessHolder.firstName} ${accessHolder.lastName}`}
                </TableCell>
              </Grid>
              <Grid item xs={12} md={3}>
                <TableCell
                  className={clsx('ah-start', classes.tableCell)}
                  data-id={`ah-start-${index}`}
                  data-value={accessHolder.startDate}
                >
                  {(small ? 'Start Date: ' : '') +
                    formatDate(accessHolder.startDate)}
                </TableCell>
              </Grid>
              <Grid item xs={12} md={3}>
                <TableCell
                  className={clsx('ah-end', classes.tableCell)}
                  data-id={`ah-end-${index}`}
                  data-value={accessHolder.endDate}
                >
                  {(small ? 'End Date: ' : '') +
                    formatDate(accessHolder.endDate)}
                </TableCell>
              </Grid>
            </Grid>
          )
        };
      }
    );
    setAccessHolders((prev) => ({
      ...prev,
      display: formatted
    }))
  }, [accessHolders.collection, selectedAccessHolders])

  const handleAddAccessHolders = () => {
    onAddClick(Object.keys(selectedAccessHolders)?.filter(k => selectedAccessHolders[k] === true));
    dispatch({ type: clearContractFilters });
    setSelectedAccessHolders({});
  };

  const handlePageChange = async (e, page) => {
    dispatch({
      type: onFiltersChanged,
      payload: { availableContractAccessHoldersPage: page }
    });
    
    await fillAvailableAccessHoldersList(page);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleSearchChange = (term) => {
    dispatch({
      type: onFiltersChanged,
      payload: { searchTerm: term }
    });
  };

  const handleSearchClick = async () => {
    dispatch({
      type: onFiltersChanged,
      payload: { availableContractAccessHoldersPage: 1 }
    });
    
    await fillAvailableAccessHoldersList(1);
  };

  return (
    <PageableEntity
      className={clsx('access-holder-pageable-entity')}
      searchBarLabel="Access Holders"
      searchBarPlaceHolder="Search for..."
      addBtnLabel="Add Access Holders to Contract"
      addBtnClicked={handleAddAccessHolders}
      onPageChange={handlePageChange}
      onSearchChange={handleSearchChange}
      hideDeleteBtn
      hideAddBtn={!editAccessHoldersAndGroupContract}
      items={accessHolders.display}
      totalCount={Math.ceil(accessHolders?.totalCount / itemLimit)}
      defaultSearch={values.accessHolderSearchTerm}
      currentPage={values.availableContractAccessHoldersPage ?? 1}
      hideFilterBtn={hideFilterBtn}
      paginatorLocation={PaginatorLocations.TOP}
      onSearchButtonClick={handleSearchClick}
      showSearchButton={true}
      showSearchDropdownOption={true}
      ShowSearchTypogrophy={true}
      onCategoryChange={handleCategoryChange}
    />
  );
};

AvailableAccessHoldersList.defaultProps = {
  onSelect: () => {},
  onAddClick: () => {},
  contractID: '00000000-0000-0000-0000-000000000000',
  facilityID: '00000000-0000-0000-0000-000000000000'
};

AvailableAccessHoldersList.propTypes = {
  onSelect: PropTypes.func,
  onAddClick: PropTypes.func
};

export default AvailableAccessHoldersList;
